import React from 'react';
import NetlifyForm from 'react-netlify-form';
import Layout from '../components/layout';
// import Hero from '../components/hero';
import SEO from '../components/seo';
import Field from '../components/field';
import states from '../data/states.json';

function GetInvolved() {
  return (
    <Layout>
      <SEO title="Get Involved" />

      {/* <Hero size="" title="Get Involved" color="primary" centered /> */}
      <div className="bg-split">
        <div className="container section">
          <div className="columns is-variable is-8">
            <div className="column content">
              <h1 className="title is-2">Get Involved</h1>
              <p>
                We're still not sure what a political campaign looks like during a pandemic, but we
                know one thing remains universal:{' '}
                <strong>
                  a grassroots campaign like ours is only as strong as its supporters.
                </strong>
              </p>
              <p>
                If you can put out a yard sign, volunteer your time, or pitch in a few dollars,
                please sign up now!
              </p>
            </div>
            <div className="column is-one-half">
              <p className="title is-6 heading">Tell Us About Yourself:</p>
              <NetlifyForm
                name="Get Involved"
                recaptcha={{
                  sitekey: '6Lc9qO4UAAAAAMqtugSDYdFnZcB6JPBTMaFjIr76',
                  size: 'invisible',
                }}
              >
                {({ recaptcha, loading, error, success }) => (
                  <div>
                    {error && <div className="notification is-danger">Please try again.</div>}
                    {success && <div>Thank you for signing up!</div>}
                    {!loading && !success && (
                      <>
                        <Field
                          type="email"
                          name="email"
                          className="input"
                          id="email"
                          placeholder="yourname@something.com"
                          required
                          label="Email Address"
                        />

                        <Field
                          type="text"
                          name="firstName"
                          className="input"
                          id="firstName"
                          required
                          label="First Name"
                        />

                        <Field
                          type="text"
                          name="lastName"
                          id="lastName"
                          required
                          label="Last Name"
                        />

                        <Field
                          type="text"
                          name="addressStreet"
                          id="addressStreet"
                          label="Street Address"
                        />

                        <Field type="text" name="addressCity" id="addressCity" label="City/Town" />

                        <Field
                          type="select"
                          name="addressState"
                          label="State"
                          defaultValue="MA"
                          options={states}
                        />

                        <Field
                          type="text"
                          name="addressZip"
                          label="Zip Code"
                          pattern="(\d{5}([\-]\d{4})?)"
                          required
                        />

                        <label className="label">How Can You Help?</label>

                        <Field
                          type="checkbox"
                          name="yardsign"
                          // checked
                          id="yardsign"
                          label="I Want a Yard Sign"
                        />

                        <Field
                          type="checkbox"
                          name="dearfriend"
                          // checked
                          id="dearfriend"
                          label="I Can Write Dear Friend Cards"
                        />

                        <Field
                          type="checkbox"
                          name="canvass"
                          // checked
                          id="canvass"
                          label="I Can Drop Literature Door to Door"
                        />

                        <Field
                          type="checkbox"
                          name="phonebank"
                          // checked
                          id="phonebank"
                          label="I Can Phone Bank"
                        />

                        <div className="field">
                          <div className="control">
                            <button className="button is-primary" type="submit">
                              Sign Up
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {recaptcha}
                  </div>
                )}
              </NetlifyForm>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default GetInvolved;
